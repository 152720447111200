/**
 * Social login component to login with Apple
 * It requires to import the script https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js
 */

var SocialLogin = (function () {
    var GOOGLE_CLIENT_ID =
        "199394228949-e422qtql0ulr7p78sa1kte3nc1lmv1lb.apps.googleusercontent.com";
    const signinToText = {
        en: "Sign in with Google",
        ca: "Inicia sessió amb Google",
        da: "Log ind med Google",
        de: "Über Google anmelden",
        el: "Σύνδεση με το Google",
        es: "Inicia sesión con Google",
        eu: "Hasi saioa Google-rekin",
        fr: "Se connecter avec Google",
        gl: "Inicia sesión con Google",
        hu: "Bejelentkezés az Google‑lel",
        it: "Accedi con Google",
        is: "Skrá inn með Google",
        nl: "Inloggen met Google",
        pl: "Zaloguj się przez Google",
        pt: "Fazer login com o Google",
        ru: "Вход с Google",
        sr: "Prijavite se pomoću Google-a",
        sv: "Logga in med Google",
        tr: "Google ile oturum açın",
        uk: "Вхід з Google",
        zh: "使用 Google 登录",
        zhtw: "使用 Google 登入",
        fi: "Kirjaudu Google-tilillä",
        ko: "Google 계정으로 로그인",
        nb: "Logg på med Google",
    };

    const signupToText = {
        en: "Sign up with Google",
        ca: "Registra't amb Google",
        da: "Tilmeld dig med Google",
        de: "Mit Google anmelden",
        el: "Εγγραφή με Google",
        es: "Registrarse con Google",
        eu: "Eman izena Google-rekin",
        fr: "S’inscrire avec Google",
        gl: "Rexistrarse con Google",
        hu: "Regisztráció az Google‑lel",
        it: "Registrati con Google",
        is: "Skráðu þig með Google",
        nl: "Aanmelden bij Google",
        pl: "Zarejestruj się przez Google",
        pt: "Registre-se com o Google",
        ru: "Регистрация с помощью Google",
        sr: "Registrujte se pomoću Google-a",
        sv: "Registrera dig med Google",
        tr: "Google ile Kaydol",
        uk: "Реєстрація з Google",
        zh: "使用 Google 注册",
        zhtw: "使用 Google 註冊",
        fi: "Rekisteröi Google-tili",
        ko: "Google 계정으로 가입하기",
        nb: "Registrer deg med Google",
    };

    var initialize = false;

    function init(state) {
        if (!initialize) {
            reinitializeApple(state);
            reinitializeGoogle(state);
            initialize = true;
        }
    }

    function reinit(state) {
        reinitializeApple(state);
        reinitializeGoogle(state);
    }

    function reinitializeApple(state) {
        AppleID.auth.init({
            clientId: "com.wikiloc.web",
            scope: "name email",
            redirectURI: "https://www.wikiloc.com/wikiloc/sociallogin.do",
            state: btoa(JSON.stringify(state)),
        });
        const buttonElement = document.getElementById("appleid-signin");
        if (buttonElement) {
            buttonElement.addEventListener("click", function () {
                AppleID.auth.signIn();
            });
        }
    }

    function reinitializeGoogle(state) {
        var googleSignInBtn = document.querySelector("#google-signin");
        if (googleSignInBtn) {
            var lang = googleSignInBtn.getAttribute("data-hl");
            var optionSignup =
                googleSignInBtn.getAttribute("data-type") === "sign-up";
            if (optionSignup) {
                googleSignInBtn.innerHTML =
                    '<span id="icon-google" class="icon"></span>' +
                    signupToText[lang];
            } else {
                googleSignInBtn.innerHTML =
                    '<span id="icon-google" class="icon"></span>' +
                    signinToText[lang];
            }
            googleSignInBtn.addEventListener("click", function () {
                var clonedState = JSON.parse(JSON.stringify(state));
                if (state.step === "WEB_APPLE") {
                    clonedState.step = "WEB_GOOGLE";
                } else if (state.step === "GARMIN_APPLE") {
                    clonedState.step = "GARMIN_GOOGLE";
                } else if (state.step === "WEB_SETTINGS_APPLE") {
                    clonedState.step = "WEB_SETTINGS_GOOGLE";
                }

                location.href =
                    "https://accounts.google.com/o/oauth2/auth?" +
                    "client_id=" +
                    GOOGLE_CLIENT_ID +
                    "&" +
                    "response_type=code&" +
                    "scope=" +
                    encodeURIComponent("openid email profile") +
                    "&" +
                    "redirect_uri=" +
                    encodeURIComponent(
                        "https://www.wikiloc.com/wikiloc/sociallogin.do"
                    ) +
                    "&" +
                    "state=" +
                    btoa(JSON.stringify(clonedState)) +
                    "&" +
                    "hl=" +
                    lang;
            });
        }
    }

    return {
        init: init,
        forceInit: reinit,
        reinitializeApple: reinitializeApple,
        reinitializeGoogle: reinitializeGoogle,
    };
})();
